import { navigate } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Button } from "react-bootstrap"
import { auth } from "../firebase"
import Layout from "../components/utils/layout"
import "../styles/SignUpPage.css"
import "../styles/createStudentAccountPage.css"
import SEO from "../components/utils/seo"
import getUserData from "../scripts/getUserData"
import dots from "../images/bgs/dots.svg"

function UnsubscribeFromUpdates(props) {
  const [authenticated, setAuthenticated] = useState(true)
  const [userData, setUserData] = useState({ userType: "guest" })

  useEffect(() => {
    const removeListener = auth.onAuthStateChanged(user => {
      if (user) {
        setAuthenticated(true)
        getUserData(user.uid)
          .then(data => {
            setUserData({ uid: user.uid, ...data })
          })
          .catch(error => {
            console.log(`Error: ${error}`)
          })
      } else {
        setAuthenticated(false)
        setUserData({ userType: "guest" })
      }
    })

    return removeListener
  }, [])

  const goToPortal = () => {
    if (userData.userType === "student") {
      navigate("/student-dashboard/")
    } else if (userData.userType === "teacher") {
      navigate("/teacher-dashboard/")
    }
  }

  const createAccount = () => {
    navigate("/Join/")
  }

  return (
    <Container className="topSpacing justify-content-center">
      <Row className="justify-content-center">
        <h3 class="noneText blackText">Unsubscribe Successful!</h3>
      </Row>

      <Row className="justify-content-center" style={{ marginTop: "50px" }}>
        {authenticated ? (
          <Button variant="success" onClick={goToPortal}>
            Continue to Portal
          </Button>
        ) : (
          <Button variant="primary" onClick={createAccount}>
            Create An Account
          </Button>
        )}
      </Row>
    </Container>
  )
}

const Unsubscribe = () => (
  <Layout image={dots}>
    <SEO title="Unsubscribe From Updates" />
    <UnsubscribeFromUpdates />
  </Layout>
)
export default Unsubscribe
